import React, { Component } from "react"
import EmblaCarouselReact from "embla-carousel-react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Images = () => {
  const data = useStaticQuery(graphql`
    query {
      backgrounds: allFile(
        filter: { sourceInstanceName: { eq: "backgrounds" } }
      ) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 1024, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)

  const images = []
  const slideTitles = ["Cowboy", "Astronaut"]

  for (const [i, node] of data.backgrounds.nodes.entries()) {
    images.push(
      <div key={"image_" + i} style={{ flex: "0 0 100%" }}>
        <div className="text-xl font-semibold">{slideTitles[i]}</div>
        <Img fluid={node.childImageSharp.fluid} alt="Image"></Img>
      </div>
    )
  }

  return images
}

class EmblaCarouselComponent extends Component {
  componentDidMount() {
    this.embla.on("select", () => {
      if (this.embla.selectedScrollSnap() === 0) {
        this.props.updateSelection("Cowboy")
      } else {
        this.props.updateSelection("Astronaut")
      }
    })
  }

  render() {
    return (
      <>
        <EmblaCarouselReact
          emblaRef={c => (this.embla = c)}
          options={{ loop: false }}
        >
          <div className="flex">
            <Images />
          </div>
        </EmblaCarouselReact>
        <div className="my-3 text-center">
          <a
            href="#/"
            className="cursor-pointer text-xl bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
            onClick={() => this.embla.scrollPrev()}
          >
            Prev
          </a>
          <a
            href="#/"
            className="cursor-pointer text-xl bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r"
            onClick={() => this.embla.scrollNext()}
          >
            Next
          </a>
        </div>
      </>
    )
  }
}

export default EmblaCarouselComponent
